//
// menu.scss
//

.navbar {
  transition: all 0.5s ease;
  padding: 20px 0px;
  .navbar-nav {
    .nav-item {
      .nav-link {
        color: $dark;
        font-size: 15px;
        font-weight: 600;
        background-color: transparent;
        margin: 0 10px;
        padding: 6px 0;
        transition: all 0.3s ease;
        &:hover,
        &:active,
        &.active {
          color: $primary !important;
        }
      }
      &.active{
        .nav-link{
          color: $primary !important;
        }
      }
    }
  }
  .navbar-toggler {
    padding: 0px;
    border: none;
    font-size: 24px;
    color: $dark;
    &:focus {
      box-shadow: none;
    }
  }
  .navbar-brand {
    .logo-light {
      display: inline-block;
    }
    .logo-dark {
      display: none;
    }
  }
}

//nav-sticy
.nav-sticky {
  &.navbar {
    background: $white;
    padding: 10px 0px;
    margin-top: 0px;
    box-shadow: 0px 3px 10px 0px rgba(#000, 0.25);
    .nav-item {
      .nav-link {
        color: $dark;
        &:hover,
        &.active,
        &:active {
          color: $primary;
        }
      }
    }
  }
  .navbar-brand {
    .logo-dark {
      display: inline-block;
    }
    .logo-light {
      display: none;
    }
  }
}

.navbar {
  &.navbar-light {
    .navbar-nav {
      .nav-item {
        .nav-link {
          color: $dark;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .navbar {
    .navbar-nav {
      .nav-item {
        .nav-link {
          color: $white;
        }
      }
    }
  }
  //nav-sticy
  .nav-sticky {
    &.navbar {
      background: $white;
      .nav-item {
        .nav-link {
          color: $dark;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .navbar {
    padding: 12px 0px;
    background-color: $white;
    .navbar-brand {
      .logo-light {
        display: none;
      }
      .logo-dark {
        display: inline-block;
      }
    }
  }
}
