.gallery {
    background-color: #2b2b2b;
    text-align: center;
  }
  
  .gallery h1,
  .gallery p {
    color: #fff;
  }
  
  /* For screens smaller than 768px */
  @media (max-width: 768px) {
    .gallery {
      margin: 0 auto;
      width: 100%;
    }
  
    .gallery .gallery-filter-button {
      width: 50%;
    }
  }
  
  /* For screens smaller than 480px */
  @media (max-width: 480px) {
    .gallery .gallery-filter-button {
      width: 100%;
    }
  }
  
  .gallery img {
    border-radius: 5px;
    box-shadow: 0px 3px 10px 0px rgba(#000, 0.25);
  }
  
  .gallery-filter-button {
    margin: 5px;
  }
  
  .sm {
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  @media (max-width: 768px) {
    .sm {
      max-width: 50%;
    }
  }
  
  @media (max-width: 480px) {
    .sm {
      max-width: 50%;
    }
  }

  .filterGap {
    gap: 5px;
  }