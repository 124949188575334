//
// about.scss
//

.skill-box {
  z-index: 3;
  position: relative;
  box-shadow: 0px 3px 10px 0px rgba(#000, 0.25);
  .progress {
    height: 8px;
    background: $gray-200;
    overflow: visible;
  }
  .progress-bar {
    position: relative;
    background-color: $primary;
    animation: animate-positive 2s;
    overflow: visible;
  }
  .progress-value {
    display: block;
    color: $dark;
    position: absolute;
    top: -31px;
    right: -25px;
  }
}

.about-img {
  position: relative;
  margin-left: -50px;
  margin-top: 0px;
  min-width: calc(50% + 50px);
  flex: none;
  @media (max-width: 991.98px) {
    margin-left: 0px;
    margin-top: 28px;
  }
}

.about-image {
  width: 575px;
  height: calc(400px * 1 / 1); /* calculate height based on width and aspect ratio */
  object-fit: cover; /* crop image to fit container */
  object-position: center; /* center image within container */
}

@keyframes animate-positive {
  0% {
    width: 0;
  }
}
