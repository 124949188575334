//
// home.scss
//

#home {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.sub-title {
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

//home-1

.bg-home {
  padding: 190px 0 150px 0;
  background-color: #192b33;
  .bg-overlay {
    background: url("../images/carDownload.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0.4;
  }
}

.home-dashboard {
  position: relative;
  border: 6px solid $white;
  border-radius: 5px;
}

.play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  a {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: $dark;
    background: $white;
    display: inline-block;
    font-size: 70px;
    border-radius: 50%;
  }
}

.shape {
  position: absolute;
  pointer-events: none;
  right: 0;
  bottom: 0px;
  left: 0;
  & > svg {
    width: 100%;
    height: auto;
  }
}

// home-2

.bg-home2 {
  padding: 190px 0 225px;
  background-image: url("../images/home/bg-home1-1.jpg");
}

.home-modal {
  border: 0;
  background-color: transparent;
  .btn-close {
    width: 24px;
    height: 24px;
    background-color: rgba($white, 0.5);
    border-radius: 4px;
    position: relative;
    top: 60px;
    right: 3px;
    z-index: 3;
    &:hover,
    &:focus {
      opacity: 0.5;
    }
  }
}

//home-3

.bg-home3 {
  padding: 210px 0px 180px 0px;
  .bg-overlay {
    background-color: rgba(#173439, 0.85);
  }
}
#myVideo {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

// home-4

.bg-home4 {
  padding: 140px 0px 116px 0px;
  background-color: $light;

  .home-img {
    width: 550px;
    box-shadow: 0px 3px 10px 0px rgba(#000, 0.25);
    height: calc(550px * 11 / 9); /* calculate height based on width and aspect ratio */
    object-fit: cover; /* crop image to fit container */
    object-position: center; /* center image within container */
  }

  @media (max-width: 1199.98px) {
    .home-img {
      width: 100%;
      height: auto;
    }
  }
}

// home-5
.bg-home5 {
  padding-top: 160px;
  padding-bottom: 85px;
  background-color: rgba($primary, 0.1);
  .bg-overlay {
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='500' preserveAspectRatio='none' viewBox='0 0 1440 500'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1071%26quot%3b)' fill='none'%3e%3cpath d='M31 500L531 0L709.5 0L209.5 500z' fill='url(%23SvgjsLinearGradient1072)'%3e%3c/path%3e%3cpath d='M345.8 500L845.8 0L1040.8 0L540.8 500z' fill='url(%23SvgjsLinearGradient1072)'%3e%3c/path%3e%3cpath d='M693.6 500L1193.6 0L1334.6 0L834.6 500z' fill='url(%23SvgjsLinearGradient1072)'%3e%3c/path%3e%3cpath d='M1392 500L892 0L803.5 0L1303.5 500z' fill='url(%23SvgjsLinearGradient1073)'%3e%3c/path%3e%3cpath d='M1092.2 500L592.2 0L259.20000000000005 0L759.2 500z' fill='url(%23SvgjsLinearGradient1073)'%3e%3c/path%3e%3cpath d='M744.4 500L244.39999999999998 0L194.89999999999998 0L694.9 500z' fill='url(%23SvgjsLinearGradient1073)'%3e%3c/path%3e%3cpath d='M1124.518196973776 500L1440 184.5181969737759L1440 500z' fill='url(%23SvgjsLinearGradient1072)'%3e%3c/path%3e%3cpath d='M0 500L315.4818030262241 500L 0 184.5181969737759z' fill='url(%23SvgjsLinearGradient1073)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1071'%3e%3crect width='1440' height='500' fill='white'%3e%3c/rect%3e%3c/mask%3e%3clinearGradient x1='0%25' y1='100%25' x2='100%25' y2='0%25' id='SvgjsLinearGradient1072'%3e%3cstop stop-color='rgba(5%2c 189%2c 147%2c 0.2)' offset='0'%3e%3c/stop%3e%3cstop stop-opacity='0' stop-color='rgba(5%2c 189%2c 147%2c 0.2)' offset='0.66'%3e%3c/stop%3e%3c/linearGradient%3e%3clinearGradient x1='100%25' y1='100%25' x2='0%25' y2='0%25' id='SvgjsLinearGradient1073'%3e%3cstop stop-color='rgba(5%2c 189%2c 147%2c 0.2)' offset='0'%3e%3c/stop%3e%3cstop stop-opacity='0' stop-color='rgba(5%2c 189%2c 147%2c 0.2)' offset='0.66'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    opacity: 0.3;
  }
}

.subscribe-form {
  background-color: $white;
  padding: 48px 44px;
  border-radius: 4px;
  .form-label {
    position: absolute;
    top: -10px;
    left: 12px;
    background: $white;
    padding: 0 6px;
    font-size: 14px;
    margin-bottom: 0;
  }
  .form-control {
    padding: 12px 17px;
    font-size: 16px;
    border-color: rgba($gray-600, 0.3);
    &:focus {
      box-shadow: none;
      border-color: rgba($gray-600, 0.45);
    }
    &::placeholder {
      color: $gray-500;
    }
  }
}

// home-6

.bg-home6 {
  padding: 160px 0px 75px 0;
  background-color: #0576b917;
  @media (max-width: 991.98px) {
    padding: 145px 0px 70px 0;
   }
}

.homeslider {
  .swiper-slide {
    transform: translate3d(0px, 0px, -200px) rotateX(0deg) rotateY(0deg) !important;
    img {
      border: 10px solid $white;
      box-shadow: $box-shadow;
    }
    .swiper-slide-shadow-left,
    .swiper-slide-shadow-right {
      background-image: linear-gradient( to left, rgba($black, 0.01), rgba($white, 0) );
    }
  }
  .swiper-slide-active {
    transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) scale(1.1) !important;
  }
}


.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after, .swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after {
  content: "";
}

.swiper-button-prev, .swiper-button-next {
  width: 40px;
  height: 40px;
  margin-top: -22px;
  line-height: 40px;
  background-color: $primary;
  text-align: center;
  font-size: 30px;
  color: $white;
  border-radius: 3px;
}
